import { api, currentCreche } from '../api'

const EventRepository = {
    index (params = null) {
        return api().$get(`/${currentCreche().uuid}/events`, {
            params
        })
    },

    show (eventUuid) {
        return api().$get(`/${currentCreche().uuid}/events/${eventUuid}`)
    },

    create (payload) {
        return api().$post(`/${currentCreche().uuid}/events`, payload)
    },

    update (eventUuid, payload) {
        return api().$put(`/${currentCreche().uuid}/events/${eventUuid}`, payload)
    },

    delete (eventUuid) {
        return api().$delete(`/${currentCreche().uuid}/events/${eventUuid}`)
    },

    attachRegistration (eventUuid, registrationUuid) {
        return api().$post(`/${currentCreche().uuid}/events/${eventUuid}/registrations/${registrationUuid}/attach`)
    },
    detachRegistration (eventUuid, registrationUuid) {
        return api().$post(`/${currentCreche().uuid}/events/${eventUuid}/registrations/${registrationUuid}/detach`)
    },

    byRegistration (registrationUuid) {
        return api().$get(`/${currentCreche().uuid}/registrations/${registrationUuid}/events`)
    }
}

export default EventRepository
